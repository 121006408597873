import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetSportById = (id: string | undefined) => {
  return useQuery({
    queryKey: ['sport', id],
    queryFn: () => (id ? api.sports.getSportById(id) : undefined),
    enabled: !!id
  });
};
export default useGetSportById;
