import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetAnnouncementById = (announcementId: string | undefined) => {
  return useQuery({
    queryKey: ['announcement', announcementId],
    queryFn: () =>
      announcementId
        ? api.announcements.getAnnouncementById(announcementId)
        : undefined,
    enabled: !!announcementId
  });
};
export default useGetAnnouncementById;
