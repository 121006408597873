import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetEquipmentById = (id: string | undefined) => {
  return useQuery({
    queryKey: ['equipment', id],
    queryFn: () => (id ? api.equipments.getEquipmentById(id) : undefined),
    enabled: !!id
  });
};
export default useGetEquipmentById;
