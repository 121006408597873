import AvailabilityReference from '@/components/common/availability-reference/AvailabilityReference';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Checkbox } from '@/components/ui/checkbox';
import { DialogFooter } from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import blockServiceSchema from '@/schemas/block-service';
import {
  MODIFIERS_STYLES_AVAILABILITY,
  getDatesByAvailability,
  getDefaultMonth
} from '@/utils/closures';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { Loader } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { z } from 'zod';
import type TBlockServiceForm from './FormBlockServices.types';
import { getUpdatedValues, hasAvailable } from './utils';

export const BlockServiceForm: React.FC<TBlockServiceForm> = ({
  availableDates,
  serviceId,
  onSubmit,
  isPending,
  availableDatesSchool = null
}) => {
  const { t } = useTranslation();

  const form = useForm<z.infer<typeof blockServiceSchema>>({
    resolver: zodResolver(blockServiceSchema),
    defaultValues: {
      date: undefined,
      time: []
    }
  });
  const time = form.getValues().date;

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit((values) => onSubmit(values, form))}
        className="space-y-8"
      >
        <FormField
          control={form.control}
          name="date"
          render={({ field }) => (
            <FormItem className="flex flex-col ">
              <FormControl className="mx-auto my-0">
                <div className="flex w-full justify-evenly">
                  <Calendar
                    mode="single"
                    selected={field.value}
                    onSelect={(e) => {
                      if (typeof e !== 'undefined') {
                        field.onChange(e);
                        form.resetField('time');
                        const allSlotsInDay =
                          availableDates[format(e, 'dd-MM-yyyy')];
                        form.setValue('time', allSlotsInDay);
                      }
                    }}
                    disabled={(date) => {
                      if (!availableDates) return true;
                      return !Object.keys(availableDates).includes(
                        format(date, 'dd-MM-yyyy')
                      );
                    }}
                    defaultMonth={getDefaultMonth(availableDates)}
                    initialFocus
                    modifiers={{
                      ...getDatesByAvailability(availableDates),
                      selectedDay: field.value
                    }}
                    modifiersStyles={MODIFIERS_STYLES_AVAILABILITY}
                  />
                  <AvailabilityReference />
                </div>
              </FormControl>
              <FormMessage />
              <p className="text-sm italic text-gray-500">
                {t('form.blockService.selectDay')}
              </p>
            </FormItem>
          )}
        />
        {time ? (
          <FormField
            control={form.control}
            name="time"
            render={() => {
              const label = format(time, "d 'de' MMMM yyyy");
              const dateKey = format(time, 'dd-MM-yyyy');

              return (
                <FormItem>
                  <FormLabel>{label}</FormLabel>
                  {availableDates ? (
                    <>
                      <FormField
                        key={`${time}-allDay`}
                        control={form.control}
                        name="time"
                        render={({ field }) => {
                          const item = 'Todo el dia';
                          return (
                            <FormItem
                              key={`${time}-allDay`}
                              className="flex flex-row items-start space-x-3 space-y-0"
                            >
                              <FormControl>
                                <Checkbox
                                  checked={!hasAvailable(field.value)}
                                  onCheckedChange={(checked) => {
                                    const updatedValues = getUpdatedValues(
                                      field.value,
                                      checked,
                                      dateKey,
                                      availableDatesSchool
                                    );

                                    field.onChange(updatedValues);
                                  }}
                                />
                              </FormControl>
                              <FormLabel className="text-sm font-normal">
                                {item}
                              </FormLabel>
                            </FormItem>
                          );
                        }}
                      />
                      {availableDates[format(time, 'dd-MM-yyyy')].map(
                        (item, index) => {
                          const blockDateBySchool =
                            availableDatesSchool?.[
                              format(time, 'dd-MM-yyyy')
                            ]?.[index]?.status;

                          return (
                            <FormField
                              key={`${time}-${item.slot}`}
                              control={form.control}
                              name="time"
                              render={({ field }) => {
                                return (
                                  <FormItem
                                    key={`${time}-${item.slot}`}
                                    className="flex flex-row items-start space-x-3 space-y-0"
                                  >
                                    <FormControl>
                                      <Checkbox
                                        disabled={
                                          blockDateBySchool === 'blocked'
                                        }
                                        checked={
                                          field.value[index].status ===
                                          'blocked'
                                        }
                                        onCheckedChange={(checked) => {
                                          const newValue = field.value;
                                          if (checked) {
                                            newValue[index].status = 'blocked';
                                          } else {
                                            newValue[index].status =
                                              'available';
                                          }
                                          return field.onChange(newValue);
                                        }}
                                      />
                                    </FormControl>
                                    <FormLabel className="text-sm font-normal">
                                      {item.slot}
                                      {blockDateBySchool === 'blocked' &&
                                        ` ${t('form.blockService.blockBy')}`}
                                    </FormLabel>
                                  </FormItem>
                                );
                              }}
                            />
                          );
                        }
                      )}
                    </>
                  ) : null}
                  <FormMessage />
                  <p className="text-sm italic text-gray-500">
                    {serviceId
                      ? t('form.blockService.blockUnblockMessage')
                      : t('settings.blockUnblockMessage')}
                  </p>
                </FormItem>
              );
            }}
          />
        ) : null}
        <DialogFooter>
          <Button type="submit" disabled={isPending}>
            {isPending ? (
              <div className="flex justify-center">
                <Loader className="w-4 h-4 animate-spin" />
              </div>
            ) : (
              t('common.save')
            )}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
};
