import type { RootStore } from '@/stores/root-store.types';
import type { StoreApi } from 'zustand';
import { LocalAnnouncement } from './announcements.types';

export const createAnnouncementsSlice = (
  set: StoreApi<RootStore>['setState'],
  get: StoreApi<RootStore>['getState'],
  ...rest: StoreApi<RootStore>[]
) => ({
  setIsHide: (id: string, value?: boolean) => {
    const currentAnnouncements = get().announcements;
    set({
      announcements: currentAnnouncements.map((announcement) =>
        announcement.id === id
          ? { ...announcement, isHide: value ?? true }
          : announcement
      )
    });
  },
  setAnnouncement: (state: LocalAnnouncement) => {
    const currentAnnouncements = get().announcements ?? [];
    if (
      currentAnnouncements.some((announcement) => announcement.id === state.id)
    ) {
      return;
    }
    set({
      announcements: [...currentAnnouncements, state]
    });
  },
  cleanAnnouncement: () => {
    set({ announcements: [] });
  }
});
