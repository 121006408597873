import UserSalesCard from '@/components/common/cards/user-sales-card/UserSalesCard';
import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import GoBack from '@/components/common/go-back/GoBack';
import NoData from '@/components/common/no-data/NoData';
import { Skeleton } from '@/components/ui/skeleton';
import useGetOrganization from '@/hooks/queries/organization-api/useGetOrganization';
import useGetSaleByConsumer from '@/hooks/queries/sales-api/useGetSalesByConsumer';
import { FileX } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const UserSalesDetail = () => {
  const params = useParams();
  const id = params.id ? params.id : '';
  const { t } = useTranslation();

  const { data: sales, isLoading, isError } = useGetSaleByConsumer(id);
  const organization = useGetOrganization();

  if (isLoading || organization.isLoading) {
    return <Skeleton className="w-full h-4/5" />;
  }
  if (!sales || !organization.data) {
    return (
      <>
        <GoBack />
        <NoData />
      </>
    );
  }
  if (isError || organization.isError) {
    return (
      <>
        <GoBack />
        <ErrorMessage />
      </>
    );
  }
  if (!sales.length) {
    return (
      <>
        <GoBack />
        <div className="flex items-start gap-2 p-6 rounded-md bg-accent">
          <FileX className="flex-shrink-0" />
          <p>{t('userSalesCard.noSales')}</p>
        </div>
      </>
    );
  }
  return (
    <>
      <GoBack />
      <div className="flex justify-center w-full">
        <UserSalesCard sales={sales} organization={organization.data} />
      </div>
    </>
  );
};

export default UserSalesDetail;
