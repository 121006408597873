import api from '@/services/api';
import { useRootStore } from '@/stores/root-store';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

const usePatchAnnouncements = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setIsHide = useRootStore((store) => store.setIsHide);

  return useMutation({
    mutationKey: ['patchAnnouncement'],
    mutationFn: (data: { payload: Partial<any>; announcementId: string }) => {
      return api.announcements.patchAnnouncement(
        data.payload,
        data.announcementId
      );
    },
    onError: (error) => {
      const errorMessage = error.message;
      toast.error(t('toast.error', { errorMessage }));
    },
    onSuccess: (data: { payload: Partial<any>; id: string }) => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'announcement' ||
          query.queryKey[0] === 'announcements'
      });
      toast.success(t('toast.announcementUpdated'));
      setIsHide(data.id, false);
      navigate(-1);
    },
    retry: false
  });
};

export default usePatchAnnouncements;
