import { ServicesCategoryValues } from '@/components/forms/form-services-category/FormServicesCategory';
import { ApiResponse } from 'apisauce';
import { Api } from './api';

export type TSports = {
  id: string;
  name: string;
  description: string;
  active: boolean;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
};

type TGetSports = {
  hasNextPage: boolean;
  offset: number;
  results: TSports[];
  total: number;
};

type TPostSports = {
  active?: boolean;
  createdAt?: string;
  createdBy?: string;
  description?: string | null;
  id: string;
  name: string;
  organization?: {
    active?: boolean;
    activeDays?: string;
    activeMonths?: string;
    address?: string;
    code?: string;
    createdAt?: string;
    createdBy?: string | null;
    currency?: string;
    description?: string | null;
    email?: string;
    icon?: string | null;
    id?: string;
    location?: string | null;
    maxCancellationTime?: number;
    name?: string;
    overdueCancellationPenalty?: number;
    phone?: string;
    postalCode?: null;
    tz?: string;
    updatedAt?: string;
    updatedBy?: string | null;
  };
  updatedAt?: string;
  updatedBy: string;
};

type TPatchSports = {
  id: string;
  description?: string;
  createdBy?: string | null;
  updatedBy?: string | null;
  updatedAt?: string;
};
export class SportsApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getSports(): Promise<TSports[]> {
    const response: ApiResponse<TGetSports> =
      await this.api.apisauce.get('sport');
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data?.results || [];
  }

  async getSportById(sportId: string): Promise<TSports | undefined> {
    const response: ApiResponse<TSports> = await this.api.apisauce.get(
      `sport/${sportId}`
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async postSports(
    body: ServicesCategoryValues
  ): Promise<TPostSports | undefined> {
    const response: ApiResponse<TPostSports> = await this.api.apisauce.post(
      `sport`,
      body
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async patchSports(
    body: Partial<ServicesCategoryValues>,
    sportId: string
  ): Promise<TPatchSports | undefined> {
    const response: ApiResponse<TPatchSports> = await this.api.apisauce.patch(
      `sport/${sportId}`,
      body
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
  async deleteSports(sportId: string): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.delete(
      `sport/${sportId}`
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
}
