import Rollbar from 'rollbar';

export const rollbarConfig: Rollbar.Configuration = {
  accessToken: process.env.POST_CLIENT_ITEM_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.ENVIRONMENT_NAME ?? 'development',
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true
      }
    }
  },
  ignoredMessages: [/Login required$/] // Se usa una expresión regular en lugar de string
};
