import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import GoBack from '@/components/common/go-back/GoBack';
import AlertModal from '@/components/common/modals/alert-modal/AlertModal';
import NoData from '@/components/common/no-data/NoData';
import FormInventoryCategory, {
  InventoryCategoryValues
} from '@/components/forms/form-inventory-category/FormInventoryCategory';
import { Skeleton } from '@/components/ui/skeleton';
import useDeleteEquipmentType from '@/hooks/queries/equipments-api/useDeleteEquipmentType';
import useGetEquipmentTypeById from '@/hooks/queries/equipments-api/useGetEquipmentTypeById';
import usePatchEquipmentTypes from '@/hooks/queries/equipments-api/usePatchEquipmentTypes';
import useGetOrganizationSports from '@/hooks/queries/sports-api/useGetOrganizationSports';
import { getChangedValues } from '@/utils/forms';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const EditInventoryCategory = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [deleteModal, setDeleteModal] = useState(false);
  const {
    data: invetoryType,
    isLoading: isLodingEquipment,
    isError: isErrorEquipment
  } = useGetEquipmentTypeById(id);
  const {
    data: sports,
    isLoading: isLoadingSports,
    isError: isErrorSports
  } = useGetOrganizationSports();
  const { mutate, isPending } = usePatchEquipmentTypes();
  const { mutate: deleteEquipmentType, isPending: isPendingDelete } =
    useDeleteEquipmentType();

  const isError = isErrorEquipment || isErrorSports;
  const isLoading = isLodingEquipment || isLoadingSports;

  if (!invetoryType || !sports || isError || isLoading) {
    return (
      <>
        <GoBack />
        {isError ? (
          <ErrorMessage />
        ) : isLoading ? (
          <Skeleton className="w-full h-[332px]" />
        ) : (
          <NoData />
        )}
      </>
    );
  }

  const defaultValues = {
    name: invetoryType?.name ?? '',
    description: invetoryType?.description ?? '',
    sports: invetoryType?.sports?.map((sport) => {
      return { label: sport.name, value: sport.id };
    })
  };

  const onSubmit = (data: InventoryCategoryValues) => {
    const onlyChangedData = getChangedValues<Partial<InventoryCategoryValues>>(
      defaultValues,
      data
    );
    mutate({
      payload: {
        ...onlyChangedData,
        sports: onlyChangedData.sports?.map((sport) => sport.value)
      },
      equipmentTypeId: invetoryType?.id ?? ''
    });
  };
  return (
    <>
      <GoBack />
      <div className="flex justify-center w-full">
        <FormInventoryCategory
          onSubmit={onSubmit}
          isPending={isPending}
          defaultValues={defaultValues}
          sports={sports}
          isPendingDelete={isPendingDelete}
          onDelete={() => setDeleteModal(true)}
        />
      </div>
      <AlertModal
        open={deleteModal}
        onOpenChange={setDeleteModal}
        title={t('modals.deleteEquipmentType.title')}
        description={t('modals.deleteEquipmentType.description')}
        cancelText={t('common.cancel')}
        acceptText={t('form.delete')}
        onAccept={() => deleteEquipmentType(invetoryType?.id ?? '')}
        buttonAcceptDestructive
      />
    </>
  );
};

export default EditInventoryCategory;
