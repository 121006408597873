import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import GoBack from '@/components/common/go-back/GoBack';
import NoData from '@/components/common/no-data/NoData';
import FormServicesCategory, {
  ServicesCategoryValues
} from '@/components/forms/form-services-category/FormServicesCategory';
import { Skeleton } from '@/components/ui/skeleton';
import useGetSportById from '@/hooks/queries/sports-api/useGetSportById';
import usePatchSports from '@/hooks/queries/sports-api/usePatchSports';
import { getChangedValues } from '@/utils/forms';
import { useParams } from 'react-router-dom';

const EditServicesCategory = () => {
  const { id } = useParams();
  const {
    data: sport,
    isPending: isPendingGet,
    isError,
    isLoading
  } = useGetSportById(id);
  const { mutate, isPending: isPendingPatch } = usePatchSports();
  const isPending = isPendingGet && isPendingPatch;

  if (!sport || isError || isLoading) {
    return (
      <>
        <GoBack />
        {isError ? (
          <ErrorMessage />
        ) : isLoading ? (
          <Skeleton className="w-full h-[332px]" />
        ) : (
          <NoData />
        )}
      </>
    );
  }

  const defaultValues = {
    name: sport?.name ?? '',
    description: sport?.description ?? ''
  };

  const onSubmit = (data: ServicesCategoryValues) => {
    const onlyChangedData = getChangedValues<Partial<ServicesCategoryValues>>(
      defaultValues,
      data
    );
    mutate({
      payload: onlyChangedData,
      sportId: sport.id
    });
  };

  return (
    <>
      <GoBack />
      <div className="flex justify-center w-full">
        <FormServicesCategory
          onSubmit={onSubmit}
          isPending={isPending}
          defaultValues={defaultValues}
          isEdit={true}
          sportId={sport.id}
        />
      </div>
    </>
  );
};

export default EditServicesCategory;
