import useGetPaginatedConsumers from '@/hooks/queries/consumer-api/useGetPaginatedConsumers';
import useGetIdentificationType from '@/hooks/queries/identification-api/useGetIdentificationType';
import usePersistedPaginationFiltersAndSorting from '@/hooks/usePersistedPaginationFiltersAndSorting';
import { TConsumer } from '@/services/consumer';
import { sortBirthdateFn, sortNameFn, sortUserFn } from '@/utils/consumer';
import {
  ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { ArrowRight, Check, EditIcon, X } from 'lucide-react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ErrorMessage from '../../error-message/ErrorMessage';
import TableTooltip from '../../tooltip/table-tooltip/TableTooltip';
import { TanStackBasicTableTableComponent } from '../common/basic-table-component/TanStackBasicTableComponent';
import TableToolBar from './TableToolBar';

const UsersTable: React.FC = () => {
  const { t } = useTranslation();

  const {
    pagination,
    setPagination,
    filtering,
    setFiltering,
    setColumnFilters,
    columnFilters,
    sorting,
    setSorting
  } = usePersistedPaginationFiltersAndSorting();

  const allIdentificationTypes = useGetIdentificationType();
  const { data, isLoading, isError, isFetching } = useGetPaginatedConsumers({
    pagination,
    filtering,
    sorting,
    columnFilters
  });
  const users = data?.results;

  const columns = useMemo<ColumnDef<TConsumer>[]>(
    () => [
      {
        id: 'id',
        accessorKey: 'id',
        header: t('tables.common.id'),
        cell: ({ row }) => {
          const id = row?.original?.id;
          return id && <TableTooltip text={id} />;
        }
      },
      {
        accessorKey: 'firstName',
        accessorFn: (originalRow) =>
          `${originalRow.firstName} ${originalRow.lastName}`,
        sortingFn: sortNameFn,
        header: t('common.name')
      },
      {
        accessorKey: 'isOwnUser',
        sortingFn: sortUserFn,
        meta: { headerText: t('common.user') },
        header: () => {
          return (
            <TableTooltip
              text={t('common.user')}
              className="max-w-[6rem] line-clamp-3"
              content={t('tables.users.userDescription')}
            />
          );
        },
        cell: ({ row }) => {
          const isUser = row.original?.isOwnUser;
          return isUser ? (
            <Check className="text-green-500" />
          ) : (
            <X className="text-red-500" />
          );
        }
      },
      {
        id: 'user.firstName',
        accessorKey: 'user.firstName',
        accessorFn: (originalRow) =>
          originalRow.user
            ? `${originalRow.user.firstName} ${originalRow.user.lastName}`
            : undefined,
        meta: { headerText: t('common.relatedUser') },
        header: () => {
          return (
            <TableTooltip
              text={t('common.relatedUser')}
              className="max-w-[6rem] line-clamp-3"
              content={t('tables.users.relatedUserDescription')}
            />
          );
        }
      },
      {
        accessorKey: 'email',
        header: t('common.email')
      },
      {
        accessorKey: 'phone',
        header: t('common.phone')
      },
      {
        accessorKey: 'birthdate',
        sortingFn: sortBirthdateFn,
        accessorFn: ({ birthdate }) =>
          birthdate ? format(birthdate, 'dd/M/y') : undefined,
        header: t('common.birth')
      },
      {
        accessorKey: 'weight',
        accessorFn: ({ weight }) => (weight ? weight + ' kg' : undefined),
        header: t('common.weight')
      },
      {
        accessorKey: 'height',
        accessorFn: ({ height }) => (height ? height + ' cm' : undefined),
        header: t('common.height')
      },
      {
        id: 'actions-edit',
        enableHiding: false,
        cell: ({ row }) => {
          const userId = row.original.id;
          return (
            <Link to={`./edit/${userId}`} className="w-full">
              <EditIcon />
            </Link>
          );
        }
      },
      {
        id: 'actions-user-sales',
        enableHiding: false,
        cell: ({ row }) => {
          const userId = row.original.id;
          return (
            <Link to={`./${userId}`} className="w-full">
              <ArrowRight />
            </Link>
          );
        }
      }
    ],
    [t]
  );

  const table = useReactTable({
    data: users ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    //pagiantion config
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    rowCount: data?.total,
    pageCount: Math.ceil((data?.total ?? 0) / (data?.limit ?? 1)),
    manualPagination: true, //turn off client-side pagination
    autoResetPageIndex: false, //turn off auto reset of pageIndex
    //sort config
    onSortingChange: setSorting,
    enableMultiSort: false,
    manualSorting: true,
    sortDescFirst: true,
    getSortedRowModel: getSortedRowModel(),
    //filter configuration
    onColumnFiltersChange: setColumnFilters,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    //filter config
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFiltering,
    initialState: {
      columnVisibility: {
        id: false
      }
    },
    state: {
      columnFilters,
      pagination,
      sorting
    }
  });

  if (isError || allIdentificationTypes.isError) {
    return <ErrorMessage />;
  }
  return (
    <>
      <TableToolBar
        table={table}
        filtering={filtering}
        setFiltering={setFiltering}
        isFetching={isFetching}
        allIdentificationTypes={allIdentificationTypes.data}
        disabled={!data}
      />
      <TanStackBasicTableTableComponent table={table} isLoading={isLoading} />
    </>
  );
};

export default UsersTable;
