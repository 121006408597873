import { Button } from '@/components/ui/button';
import useGetEquipmentTypes from '@/hooks/queries/equipments-api/useGetEquipmentTypes';
import usePersistedPaginationFiltersAndSorting from '@/hooks/usePersistedPaginationFiltersAndSorting';
import type { TEquipmentType } from '@/services/equipments/equipments.types';
import { getFeatureFlagValue } from '@/utils/utils';
import {
  type ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { EditIcon, PlusIcon } from 'lucide-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import ErrorMessage from '../../error-message/ErrorMessage';
import TableColumnsToggle from '../../table-columns-toggle/TableColumnsToggle';
import TableTooltip from '../../tooltip/table-tooltip/TableTooltip';
import { TanStackBasicTableTableComponent } from '../common/basic-table-component/TanStackBasicTableComponent';
import TableSearchInput from '../common/table-search-input/TableSearchInput';

export function InventoryCategoriesTable() {
  const { data: inventory, isLoading, isError } = useGetEquipmentTypes();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    pagination,
    setPagination,
    filtering,
    setFiltering,
    sorting,
    setSorting
  } = usePersistedPaginationFiltersAndSorting({
    defaultSorting: [{ id: 'name', desc: false }]
  });

  const featureFlagCrudValue = getFeatureFlagValue('crud');

  const columns = useMemo<ColumnDef<TEquipmentType>[]>(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: t('tables.inventory.columns.name')
      },
      {
        id: 'description',
        accessorKey: 'description',
        header: t('tables.inventory.columns.description'),
        accessorFn: ({ description }) => {
          return !description ? '-' : description;
        }
      },
      {
        id: 'sports',
        accessorKey: 'sports',
        accessorFn: ({ sports }) => {
          const stringSport = sports.map((sport) => sport.name).join(', ');
          return stringSport;
        },
        meta: { headerText: t('tables.inventoryCategory.serviceCategories') },
        header: () => {
          return (
            <TableTooltip
              text={t('tables.inventoryCategory.serviceCategories')}
              className="max-w-[6rem] line-clamp-3"
              content={t(
                'tables.inventoryCategory.serviceCategoriesDescription'
              )}
            />
          );
        }
      },
      {
        id: 'id',
        accessorKey: 'id',
        header: t('tables.common.id'),
        cell: ({ row }) => {
          const id = row?.original?.id;
          return (
            <TableTooltip text={id} className="max-w-[20rem] line-clamp-1" />
          );
        }
      },
      {
        id: 'actions-edit',
        enableHiding: false,
        cell: ({ row }) => {
          const categoryId = row.original.id;
          if (!featureFlagCrudValue) {
            return undefined;
          }
          return (
            <Link to={`./edit/${categoryId}`} className="w-full">
              <EditIcon />
            </Link>
          );
        }
      }
    ],
    [t, featureFlagCrudValue]
  );
  const emptyData = (inventory?.length ?? 0) === 0;

  const table = useReactTable({
    data: inventory ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    //pagination config
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    autoResetPageIndex: false, //turn off auto reset of pageIndex
    //sort config
    enableMultiSort: false, // disable multi-sorting for the entire table
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    //filter config
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFiltering,
    state: {
      sorting,
      globalFilter: filtering,
      pagination
    }
  });

  if (isError) {
    return <ErrorMessage />;
  }
  return (
    <>
      <div
        className={`flex items-center gap-2 py-4 ${featureFlagCrudValue ? 'justify-between' : 'justify-end'}`}
      >
        {featureFlagCrudValue && (
          <Button
            onClick={() => navigate('./create')}
            className="flex items-center gap-2"
          >
            <PlusIcon className="w-5" />
            <p>{t('form.create')}</p>
          </Button>
        )}
        <div className="flex items-center gap-2">
          <TableSearchInput
            value={filtering ?? ''}
            onChange={(event) => {
              setFiltering(event.target.value);
            }}
          />
          <TableColumnsToggle disabled={emptyData} table={table} />
        </div>
      </div>
      <TanStackBasicTableTableComponent table={table} isLoading={isLoading} />
    </>
  );
}
