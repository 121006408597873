import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import useDeleteConsumerImage from '@/hooks/queries/image-api/useDeleteConsumerImage';
import useGetConsumerImage from '@/hooks/queries/image-api/useGetConsumerImage';
import usePostFile from '@/hooks/queries/image-api/usePostFile';
import { GetConsumerImageDto } from '@/services/file';
import { Loader, UploadIcon } from 'lucide-react';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { toast } from 'sonner';

const FormUploadConsumerImage: React.FC<{ id: string }> = ({ id }) => {
  const { t } = useTranslation();

  const { data: image, isFetching } = useGetConsumerImage(id);
  const {
    mutate: uploadFile,
    isPending: isUploading,
    isSuccess: isSuccessUpload,
    isError
  } = usePostFile(id);
  const {
    mutate: deleteImage,
    isPending: isDeleting,
    isSuccess: isSuccessDelete
  } = useDeleteConsumerImage(id);

  const isLoading =
    isFetching && !image && (isSuccessUpload || isSuccessDelete);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadImage = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      uploadFile({ file });
    }
  };

  const handleDelete = () => {
    deleteImage((image as GetConsumerImageDto).id);
  };

  const handleDownload = async () => {
    try {
      const response = await fetch((image as { url: string }).url);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'imagen';
      link.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error(t('toast.errorDownloadImage'), { duration: 5000 });
    }
  };

  useEffect(() => {
    if (isError) {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  }, [isError]);

  // render skeleton if is wating response with possible data
  if (isLoading)
    return (
      <div className="flex flex-col gap-3 p-8 mt-5">
        <Skeleton className="w-full h-7" />
        <Skeleton className="w-full h-7" />
        <Skeleton className="w-full h-11" />
      </div>
    );

  return (
    <div className="flex flex-col gap-3 p-8 mt-5">
      <div>
        <p className="font-bold text-md">{t('form.info')}</p>
        <p>{t('form.uploadImageDescription')}</p>
      </div>
      {image ? (
        <div>
          <Zoom>
            <img
              src={image.url}
              alt="Imagen actual"
              className="cursor-pointer"
            />
          </Zoom>
          <div className="flex gap-2 mt-2">
            <Button
              onClick={handleDownload}
              disabled={isDeleting || !image.url}
            >
              {t('common.downloadTable')}
            </Button>
            <Button
              variant="destructive"
              onClick={handleDelete}
              disabled={isDeleting || !image.url}
            >
              {isDeleting ? (
                <Loader className="w-4 h-4 mr-2 animate-spin" />
              ) : (
                <p>{t('form.delete')}</p>
              )}
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <Button
            onClick={handleUploadImage}
            className="flex items-center w-full gap-2"
            disabled={isUploading}
          >
            {isUploading ? (
              <Loader className="w-4 h-4 mr-2 animate-spin" />
            ) : (
              <>
                <UploadIcon className="w-5" />
                <p>{t('common.uploadImage')}</p>
              </>
            )}
          </Button>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </div>
      )}
    </div>
  );
};

export default FormUploadConsumerImage;
