import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import GoBack from '@/components/common/go-back/GoBack';
import NoData from '@/components/common/no-data/NoData';
import FormAnnouncements, {
  AnnouncementsValues
} from '@/components/forms/form-announcements/FormAnnouncements';
import { Skeleton } from '@/components/ui/skeleton';
import useGetAnnouncementById from '@/hooks/queries/announcements-api/useGetAnnouncementById';
import usePatchAnnouncements from '@/hooks/queries/announcements-api/usePatchAnnouncements';
import useGetOrganizationFromCache from '@/hooks/queries/organization-api/useGetOrganizationFromCache';
import { getTimestampUTC } from '@/utils/dates';
import { getChangedValues } from '@/utils/forms';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

const EditAnnouncements = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: announcement, isError, isLoading } = useGetAnnouncementById(id);
  const { mutate, isPending } = usePatchAnnouncements();
  const organization = useGetOrganizationFromCache();
  if (!announcement || isError || isLoading) {
    return (
      <>
        <GoBack />
        {isError ? (
          <ErrorMessage />
        ) : isLoading ? (
          <Skeleton className="w-full h-[332px]" />
        ) : (
          <NoData />
        )}
      </>
    );
  }

  const defaultValues = {
    title: announcement.title,
    description: announcement.description,
    startTime: new Date(announcement.startTime),
    endTime: new Date(announcement.endTime),
    audience: announcement.audience
  };

  const onSubmit = (data: AnnouncementsValues) => {
    type partialType = Omit<AnnouncementsValues, 'startTime' | 'endTime'> & {
      startTime: number;
      endTime: number;
    };
    const onlyChangedData = getChangedValues<Partial<partialType>>(
      {
        ...defaultValues,
        startTime: announcement.startTime,
        endTime: announcement.endTime
      },
      {
        ...data,
        startTime: getTimestampUTC({
          date: data.startTime,
          time: '00:00:00',
          tz: organization?.tz ?? 'America/Argentina/Buenos_Aires'
        }),
        endTime: getTimestampUTC({
          date: data.endTime,
          time: '23:59:59',
          tz: organization?.tz ?? 'America/Argentina/Buenos_Aires'
        })
      }
    );
    if (Object.keys(onlyChangedData).length > 0) {
      mutate({
        payload: onlyChangedData,
        announcementId: announcement.id
      });
    } else toast.error(t('toast.noChanges'));
  };

  return (
    <>
      <GoBack />
      <div className="flex justify-center w-full">
        <FormAnnouncements
          onSubmit={onSubmit}
          isPending={isPending}
          isEdit={true}
          defaultValues={defaultValues}
          announcementId={announcement.id}
        />
      </div>
    </>
  );
};

export default EditAnnouncements;
