import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { Globe } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export default function LanguageChanger() {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;
  const primaryLanguage = currentLocale;
  const handleChangeLanguage = (value: string) => {
    i18n.changeLanguage(value);
  };

  return (
    <Select value={primaryLanguage} onValueChange={handleChangeLanguage}>
      <SelectTrigger className="justify-between w-30">
        <Globe size={15} className="mr-1" />
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem value="en">{t('common.language.english')}</SelectItem>
          <SelectItem value="es">{t('common.language.spanish')}</SelectItem>
          <SelectItem value="esAr">
            {t('common.language.argentino')}
          </SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
