import { TBlockServiceSchedule } from '@/services/services';
import { SortingFn } from '@tanstack/react-table';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { differenceInYears } from 'date-fns';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
function convertToTimestamp(dateStr: string): number {
  const dateObj = new Date(dateStr);
  const timestamp = dateObj.getTime();
  return timestamp;
}

export const sortTimeStampStartTimeFn: SortingFn<any> = (
  rowA,
  rowB
): number => {
  const statusA = rowA.original.startTime;
  const statusB = rowB.original?.startTime ? rowB.original.startTime : 0;

  if (!statusA) {
    return 0;
  }
  return statusA - statusB;
};

export const sortTimeISOCreatedAtFn: SortingFn<any> = (rowA, rowB): number => {
  const statusA = rowA.original?.createdAt
    ? new Date(rowA.original.createdAt).getTime()
    : 0;
  const statusB = rowB.original?.createdAt
    ? new Date(rowB.original.createdAt).getTime()
    : 0;
  if (!statusA) {
    return 0;
  }
  return statusA - statusB;
};

export const sortStartTimeFn: SortingFn<any> = (rowA, rowB): number => {
  const statusA = convertToTimestamp(rowA.original.startTime);
  const statusB = convertToTimestamp(
    rowB.original?.startTime ? rowB.original.startTime : 0
  );

  if (!statusA) {
    return 0;
  }

  return statusA - statusB;
};

export const sortTimeStampPaidAtFn: SortingFn<any> = (rowA, rowB): number => {
  const statusA = rowA.original.paidAt;
  const statusB = rowB.original.paidAt;

  return statusA - statusB;
};

export const sortUpdatedAtFn: SortingFn<any> = (rowA, rowB): number => {
  const statusA = convertToTimestamp(rowA.original.updatedAt);
  const statusB = convertToTimestamp(rowB.original.updatedAt);

  return statusA - statusB;
};

export type TCheckModalState =
  | {
      data: {
        closures: TBlockServiceSchedule;
        serviceId?: string;
      };
      state: true;
    }
  | {
      data: undefined;
      state: false;
    };

export const minHeight = 110;
export const maxHeight = 250;

export const maxHeightInputValidation = (value: number) =>
  value >= 0 && value <= maxHeight;

export const minWeight = 5;
export const maxWeight = 500;

export const maxWeightInputValidation = (value: number) =>
  value >= 0 && value <= maxWeight;

export const ALLOW_PAYMENT = {
  admin: 'admin',
  all: 'all'
};

export const CAN_UPDATE_RESERVATIONS = {
  admin: 'admin',
  all: 'all'
};

export enum DESCRIPTION_MAX_LENGTH {
  short = 255,
  medium = 511,
  long = 1023
}

export const parentsVEP = ['ALUAR', 'INFA', 'Ninguno'];

export function getAgeRange(birthDate: Date): string {
  const currentDate = new Date();
  const age = differenceInYears(currentDate, birthDate);
  const cutoffDate = new Date(birthDate.getFullYear(), 5, 30); // 30 de junio del año de nacimiento

  // Ajuste de la edad para los alumnos que cumplen años después del 30 de junio
  // Si cumplen 5, 7 o 9 años después del 30 de junio, se les suma 1 a la edad
  // para determinar el grupo de edad al que pertenecen
  let adjustedAge = age;
  if (birthDate > cutoffDate) {
    if (age === 5 || age === 7 || age === 9) {
      adjustedAge = age + 1;
    }
  }

  if (adjustedAge >= 6 && adjustedAge <= 7) {
    return '6-7';
  } else if (adjustedAge >= 8 && adjustedAge <= 9) {
    return '8-9';
  } else if (adjustedAge >= 10) {
    return '+10';
  } else {
    return '4-5';
  }
}

export const ageGroups = {
  'group-4-5': '4-5',
  'group-6-7': '6-7',
  'group-8-9': '8-9',
  'group-+10': '+10'
};

export const getGroup = (additionalData: any) => {
  const group = additionalData?.group as keyof typeof ageGroups;
  return ageGroups[group] || 'No';
};

export const VEPCodes = ['viento-en-popa', 'incubator-vp'];
