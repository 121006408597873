import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetEquipmentTypeById = (id: string | undefined) => {
  return useQuery({
    queryKey: ['equipmentType', id],
    queryFn: () => (id ? api.equipments.getEquipmentTypeById(id) : undefined),
    enabled: !!id
  });
};
export default useGetEquipmentTypeById;
