import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import GoBack from '@/components/common/go-back/GoBack';
import NoData from '@/components/common/no-data/NoData';
import FormInventory, {
  InventoryValues
} from '@/components/forms/form-inventory/FormInventory';
import { Skeleton } from '@/components/ui/skeleton';
import useGetEquipmentTypes from '@/hooks/queries/equipments-api/useGetEquipmentTypes';
import usePatchEquipment from '@/hooks/queries/equipments-api/usePatchEquipment';
import useGetEquipmentById from '@/hooks/queries/equipments-api/usGetEquipmentById';
import { getChangedValues } from '@/utils/forms';
import { useParams } from 'react-router-dom';

const EditInventory = () => {
  const { id } = useParams();
  const {
    data: equipmentItem,
    isError: isErrorGetEquipment,
    isLoading: isLoadingGetEquipment
  } = useGetEquipmentById(id);
  const { mutate, isPending } = usePatchEquipment();
  const {
    data: inventoryCategories,
    isLoading: isLoadingEquipmentTypes,
    isError: isErrorEquipmentTypes
  } = useGetEquipmentTypes();

  const isError = isErrorEquipmentTypes || isErrorGetEquipment;
  const isLoading = isLoadingEquipmentTypes || isLoadingGetEquipment;

  if (!equipmentItem || !inventoryCategories || isError || isLoading) {
    return (
      <>
        <GoBack />
        {isError ? (
          <ErrorMessage />
        ) : isLoading ? (
          <Skeleton className="w-full h-[332px]" />
        ) : (
          <NoData />
        )}
      </>
    );
  }

  const defaultValues = {
    name: equipmentItem?.name ?? '',
    code: equipmentItem?.code ?? '',
    description: equipmentItem?.description ?? '',
    equipmentType: equipmentItem?.equipmentType?.id ?? ''
  };

  const onSubmit = (data: InventoryValues) => {
    const onlyChangedData = getChangedValues<Partial<InventoryValues>>(
      defaultValues,
      data
    );
    mutate({
      payload: onlyChangedData,
      equipmentId: equipmentItem.id
    });
  };

  return (
    <>
      <GoBack />
      <div className="flex justify-center w-full">
        <FormInventory
          onSubmit={onSubmit}
          isPending={isPending}
          defaultValues={defaultValues}
          inventoryCategories={inventoryCategories}
        />
      </div>
    </>
  );
};

export default EditInventory;
